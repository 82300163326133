.home_first-section {
  min-height: 500px;
  height: 77vh;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

}



.home_second-section-data {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}

.goals-container {
  justify-content: space-between;
}

.home_second-section-data img {
  max-width: 180px;
  max-height: 150px;
}

.home_second-section_title {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: center;
}

.home_second-section_description {
  line-height: 1.3;
  font-size: 12px;
  padding: 0 25px;
  text-align: center;
}

.partners-section {
  animation: slide-right 0.3s ease-in-out 0.1s 1 forwards alternate;
  transform: translateX(100%);
}

@keyframes slide-right {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

.partners-section .swiper {
  margin: 0;
  height: 25vh;
  min-height: 100px;
}

.partners-section .swiper-slide {
  display: flex;
  justify-content: center;
  min-width: 15%;
  width: 15%;
}

.swiper-slide a {
  width: 100%;
  display: flex;
  justify-content: center;
}

.partners-section a {
  cursor: pointer;
}



.partners-section img {
  width: 80% !important;
  height: 35px !important;
}


.act-title {
  font-size: 13px !important;

}



.act-title p:first-child {
  font-size: 40px !important;
  margin: 0 !important;
}







.home_high-five-section-icon-container {
  position: relative;
  display: flex;
  justify-content: center;
}

.home_high-five-section-icon-container img {
  position: absolute;
  margin-top: 25px;
  top: -8.5rem;
  width: 18rem;
}


@media (max-width:992px) {
  .goals-container {
    justify-content: center;
  }
}

@media (max-width:768px) {
  .home_high-five-section-icon-container img {
    top: -6.5rem;
    width: 13rem;
  }

  .partners-section .swiper-slide {
    display: flex;
    justify-content: center;
  }
}