.campaigns-list .light-blue-container {
    min-height: 16rem;
}

.campaigns-title {
    font-size: 35px;
    line-height: 1;
    text-align: center;
    margin: 15px 0;
    position: relative;
    font-style: italic;
}

.campaign-num {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 150px;
    color: var(--blue);
    font-style: italic;
    opacity: 0.4;
    font-weight: bold;
}

.campaign-num.single {
    top: -150%
}

.campaigns-text-img {
    margin: 0 auto;
    top: -8%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.campaigns-text-img img,
.campaigns-text-img_single img {
    width: 20rem;
}

.campaigns-text-img.single {
    margin: 15px auto;
}

.read-more {
    border: 1px solid var(--blue);
    padding: 8px 10px;
    margin-top: 10px;
    border-radius: 10px;
    transition: all ease-in-out 0.3s;
    cursor: pointer;
    text-transform: capitalize;
    font-size: 14px;
}

.read-more:hover {
    background-color: var(--blue);
    color: #ffffff;
}

@media (max-width:992px) {
    .campaigns-title {
        font-size: 30px;
    }

    .campaign-num {
        font-size: 145px;
    }

    .campaign-num.single {
        top: -140%
    }
}

@media (max-width:768px) {
    .campaigns-title {
        font-size: 25px;
    }

    .campaign-num {
        font-size: 125px;
    }


}

@media (max-width:600px) {
    .campaigns-title {
        font-size: 25px;
    }

    .campaign-num {
        font-size: 100px;
    }

    .campaign-num.single {
        top: -25px
    }
}