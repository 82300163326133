.youth-section-title {
  font-size: 35px;
  line-height: 1;
  text-align: center;
  margin: 15px 0;
}

.youth-section-title span {
  font-style: italic;
}

.youth-data-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.youth-data-card img {
  max-width: 160px;
  min-height: 120px;
}

.youth-data_title {
  font-weight: bold;
  margin: 10px 0;
}

.youth-data_description {
  line-height: 1.3;
  font-size: 12px
}

@media (max-width:992px) {
  .youth-section-title {
    font-size: 30px;
  }
}

@media (max-width:768px) {
  .youth-section-title {
    font-size: 25px;
  }
}

@media (max-width:600px) {
  .youth-section-title {
    font-size: 20px;
  }
}