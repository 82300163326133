.aos-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.text-slide-right {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 0.5s, transform 0.5s;
}

.text-slide-right.aos-animate {
  opacity: 1;
  transform: translateX(0);
}