.project-page {

  background-color: var(--light-gray);
  position: relative;
  font-size: 35px;

}

.project-page h5:first-child::after {
  content: "";
  margin-top: 5px;
  height: 5px;
  border-radius: 10px;
  width: 25px;
  display: block;
  background-color: var(--blue);
}

.project-page svg {
  color: var(--blue);
}

.project-page img {
  width: 100%;
  height: 100%;
}

.project-page h3,
.project-page h5 {
  font-weight: bold !important;

}

.project-page h6 {
  color: var(--blue);
  border: 1px solid var(--blue);
  border-radius: 8px;
  font-weight: bold;
  padding: 10px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  text-align: center;
}

.event-location {
  margin: 0 5px;
}


.project-page h6:hover {
  background-color: var(--blue);
  color: var(--white);
  border: 1px solid var(--white);


}

.position svg {
  position: absolute;
  color: var(--light-gray);
  color: var(--blue);
}


.animated-icons__square svg {
  font-size: 200px;
  left: -10%;
  bottom: -10%;
  z-index: 3 !important;
  animation: rotate 10s linear infinite;

}



.animated-icons__close svg {
  bottom: 30%;
  right: 10%;
  animation: rotate-x 10s linear infinite;
  font-size: 30px;
}

.animated-icons__gray-bar {
  position: absolute;
  border: 10px solid var(--blue);
  height: 40%;
  width: 50%;
  top: -10%;
  right: -25%;
  transform: rotate(-20deg);
  border-bottom-left-radius: 300px;
  border-top-left-radius: 300px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}



@keyframes rotate-x {

  0%,
  100% {
    transform: rotate(0deg);
  }

  40%,
  60% {
    transform: rotate(360deg);
  }
}


.event-card {
  border: 1px solid var(--footer-contact-border);
  border-radius: 8px;
  cursor: pointer;
  min-height: 35vh;
}

.all-events h3 {
  font-weight: 700;
  text-transform: uppercase;
  margin: 0 auto;
}

.all-events h3::after {
  display: block;
  content: "";
  background-color: #000;
  height: 5px;
  width: 50%;
  border-radius: 5px;
  margin: 10px auto;
}

.event-card h5 {
  font-weight: bold;
}

.project-desc {
  text-align: center;
  font-size: 15px;
}

.event-card p {
  font-size: 12px;
}

.event-card img {
  width: 100%;
  border-radius: 8px;
}

.event-card h6 {
  margin-bottom: 0;
  font-weight: bold;
}

@media (max-width:768px) {
  .animated-icons__square svg {
    font-size: 150px;
  }

  .event-card img {
    height: 13rem;
  }

  .event-time,
  .event-location,
  .event-page-link {
    font-size: 12px !important;
  }
}