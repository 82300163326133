.agency-img-conainer {
    position: relative;
    top: -5rem
}

.agency-img-conainer img {
    width: 300px;
}

.agency-title-desc-container {
    position: relative;
}

.numbers {
    width: 60px;
}

.agency-title {
    font-weight: bold;
    text-align: center;
}

.agency-desc {
    font-size: 13px;
}


.arrow-1,
.arrow-2 {
    position: absolute;
    height: 100px;
}

.arrow-2 {
    right: 0;
}