.swiper-slide {
  border-radius: 10px;
  padding: 10px;
  min-width: 400px;
  overflow: hidden;
  height: 320px !important;
  position: relative;

}

.swiper-slide::-webkit-scrollbar {
  display: none;
}

.swiper-slide img {
  max-width: 150px;
  height: 100px;

}

.swiper-button-prev:after {
  content: "";
  display: none;
}

.slide-title {
  margin: 0;
  margin-top: 8px;
  font-weight: bold;

}

.slide-read-more {
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold !important;
  position: absolute;
  font-weight: 500;
  text-transform: capitalize;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 2;
  background-color: rgba(255,
      255,
      255, 0.7)
}

.slide-read-more span {
  cursor: pointer;
}

.slide-read-more span:hover {
  text-decoration: underline;
}

.slide-desc {
  font-size: 12px;
  line-height: 1.4;
  height: 60%;
  overflow: hidden;
}

.swiper-button-next:after {
  content: "";
  display: none
}

.swiper-button-prev,
.swiper-button-next {
  all: unset !important;
}


.swiper-buttons-container {
  display: flex;
  width: 100%;
  position: absolute;
  justify-content: flex-end;
  top: -35px;
}

.swiper-buttons-container img {
  width: 40px !important;
  margin: 0 5px;
  cursor: pointer;
}


@media (max-width:1000px) {
  .swiper-slide {
    min-width: initial;
    width: 100% !important;
  }



  .swiper-buttons-container {
    justify-content: center;
  }

}