.common-button,
a.common-button {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 15px !important;
  border-radius: 1px !important;
  padding: 7px inherit;
  text-transform: capitalize;
  text-decoration: none !important;
  cursor: pointer;
}

a.common-button {
  padding: 6px 12px !important;
}

.common-button img {
  margin: 0 5px;
  width: 25px;
}

.common-button:focus {
  box-shadow: none !important;
}

.common-button svg {
  margin-left: 10px;
}

button.btn,
a.common-button {
  text-align: center;
  width: 100%;
}

.common-button:focus,
a.common-button:focus {
  box-shadow: none !important;
  outline: none !important;
}

.common-button.btn-primary,
a.common-button,
.common-button.btn-primary:hover,
a.common-button {
  transition: all 0.5s !important;
  color: var(--light-gray) !important;
  background-color: var(--secondary-font-color) !important;
  border: none !important;
}



.common-button.btn-secondary,
.common-button.btn-secondary:hover {
  transition: all 0.5s;
  color: var(--black);
  background-color: inherit !important;
  border-color: none;
  border: 0;
  text-emphasis: center;

}



.common-button.btn-primary:visited,
.common-button.btn-secondary:visited {
  color: initial;
  background-color: none;
  border-color: none;
}

@media (max-width:992px) {


  .common-button>div {
    font-size: 15px;
  }
}

@media (max-width:768px) {


  .common-button>div {
    font-size: 12px;
  }
}