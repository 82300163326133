.modal-conatiner {
  position: absolute;
  display: flex;
  justify-content: center;
  cursor: pointer;
  top: -100%;
  left: 0%;
  bottom: 0;
  z-index: 1000;
  right: 0%;
  background-color: rgba(0, 0, 0, 0.7);
}

.common-modal {
  z-index: 1001;
  position: fixed;
  top: 5%;
  background-color: var(--white);
  max-height: fit-content;
  width: 50%;
  left: 25%;
  right: 25%;
  border-radius: 8px;
  padding: 20px;
  max-height: 90vh;
  overflow-y: auto;
}

.common-modal::-webkit-scrollbar {
  width: 6px;
}

.common-modal::-webkit-scrollbar-thumb {
  background-color: #000000;
  border-radius: 6px;
}

.common-modal::-webkit-scrollbar-track {
  background-color: #ffffff;
  border-radius: 8px;
}

.common-modal * {
  scrollbar-color: #000000 #ffffff;
  scrollbar-width: thin;
}

.common-modal *::-ms-scrollbar {
  width: 6px;
}

.common-modal *::-ms-scrollbar-thumb {
  background-color: #000000;
  border-radius: 6px;
}

.common-modal *::-ms-scrollbar-track {
  background-color: #ffffff;
}

.common-modal_title {
  font-size: 24px;
  border-bottom: 1px solid var(--black);

}

.common-modal_title svg {
  cursor: pointer;
}

.common-modal img {
  width: 100px !important;
  margin: 0 5px;
  cursor: pointer;
}


@media (max-width:992px) {
  .common-modal {
    width: 60%;
    left: 20%;
    right: 20%;
  }
}

@media (max-width:768px) {
  .common-modal {
    width: 95%;
    left: 2.5%;
    right: 2.5%;
  }
}