.team-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  height: 16rem;
}

.team-card img {
  margin-bottom: 10px;
  max-height: 160px;
}

.team-card p {
  padding: 0 !important;
  margin: 0 !important;
}

.team-details {
  text-align: center;
}

.team-section-title {
  font-size: 30px;
  line-height: 1;
  text-align: center;
  margin: 15px 0;
}



.team-details .team-name {
  font-weight: bold;
}