.loading-section {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: var(--light-gray);
    z-index: 15;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loading-section img {
    animation: resize 0.5s ease-in-out 0s alternate infinite;
}

@keyframes resize {
    0% {
        transform: scale(0.8);
    }

    100% {
        transform: scale(1);
    }
}