:root {
  --dark-gray: #707070;
  --light-gray: rgb(235, 235, 235);
  --blue: #4884E6;
  --light-blue: #DAE2ED;
  --white: #FFFFFF;
  --font-color: #333333;
  --secondary-font-color: #335FA1;
  --footer-contact-border: #c8d3e6;
  --footer-background-color: #D9E0ED;
  --arabic-font: dorid;
  --english-font: acumin;
}