.nav img {
  position: absolute;
  width: 100%;
  height: 85%;
  top: 5%;
}
.daring-hub,
.apply {
  position: relative;
  display: inline-block;
    padding: 0 15px;
}

.daring-hub img,
.apply img {
  left: -3%;
  top: -30%;
    width: 100%;
    height: 150%;
}


.nav-link {
  z-index: 3;
}

.nav-link.active {
  font-weight: bold;
}