.tabs-list {
  list-style-type: none;
  display: flex;
  justify-content: space-around;
  width: 50%;
  margin: auto;
  margin-top: 10px;
  padding: 0;
}



.tab {
  font-size: 14px;
  padding: 10px;
  cursor: pointer;
  text-transform: capitalize;
  text-align: center;
}

.tab.active {
  font-weight: bold;
}


.border-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
}

.border-container_bottom {
  position: absolute;
  transition: all ease-in-out 0.3s;
  height: 7px;
  width: 100%;
}

.tab.not-active .border-container_bottom {
  left: -100%;
  opacity: 0;
}

.tab.active .border-container_bottom {
  left: 0;
  opacity: 1;
}

.tabs-container {
  min-height: 20rem;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.tab-pannels {
  position: relative;
  box-sizing: border-box;
}

.tab-pannel {
  width: 100%;
  min-height: 280px;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  transition: all ease-in-out;
  box-sizing: border-box;
}

.tab-title {
  text-transform: capitalize;
  font-weight: bold;
  font-size: 14px;
}

.ar-title-desc p {
  text-align: start;
}

.tab-title::after {
  content: " :";
}

.tab-pannel img {
  width: 200px;
  height: 200px;
}

.hide-content .tab-pannel {
  display: none !important;
}




@media (max-width:992px) {
  .tabs-list {
    width: 75%;
  }

  .tabs-container {
    min-height: 22rem;
  }



}

@media (max-width:768px) {
  .tab-pannels {
    min-height: 500px;
  }

  .tabs-list {
    justify-content: space-around;
    width: 100%;
  }

  .tabs-container {
    min-height: 25rem;
  }
}

@media (max-width:600px) {
  .tabs-container {
    min-height: 28rem;
  }

}

@media (max-width:320px) {
  .tabs-container {
    min-height: 33rem;
  }
}