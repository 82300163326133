.footer {
  background-color: var(--footer-background-color);
  font-size: 14px;
  padding: 25px 0;
  background-size: 90%;
  background-position-x: 25%;
  background-image: url("../../assets/images/footer-backround.png");
  background-repeat: no-repeat;
  text-transform: capitalize;
}

.footer.ar {
  direction: rtl;
}

.footer a,
.footer a:hover {
  color: var(--font-color) !important;
  padding-left: 0;
  padding-right: 0;
  font-size: 12px;
}

.footer_contact-us {
  font-size: 12px;
  border-top: 0.5px solid var(--footer-contact-border);
}

.footer a:hover {
  text-decoration: underline;
}

.footer .logo {
  width: 50%;
  cursor: pointer;
}

.footer_social-media {
  display: flex;
  margin-top: 10px;
}

.footer_social-media-icon {
  font-size: 15px;
  margin-right: 12px;
}

.footer_social-media-icon svg {
  cursor: pointer !important;
  color: var(--secondary-font-color) !important;
}


.footer_page-name {
  margin-bottom: 14px;
  font-weight: bold;
}

.footer.ar .nav,
.footer.ar .footer_logo-container {
  display: flex;
}

.footer.ar .footer_page-name,
.footer.ar .footer_contact-us span {
  text-align: right;
}

.footer.ar .footer_logo-container {
  justify-content: start;
}

@media (max-width:768px) {
  .footer {
    background-image: none;

  }

  .footer_page-name {
    margin-top: 5px;
  }
}