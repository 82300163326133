.light-blue-container {
    background-color: var(--light-blue);
    min-height: 15rem;
    position: relative;
}



.light-blue-container .title {
    font-size: 40px;
    margin: 0;
    line-height: 1;
    text-align: center;
}

.light-blue-container .description {
    font-size: 14px;
    text-align: center !important;
}



.light-blue-arrow {
    position: absolute;
    right: 25%;
    bottom: -85px;
}

@media (max-width:992px) {

    .light-blue-container .title {
        font-size: 30px;

    }
}

@media (max-width:768px) {
    .light-blue-container .title {
        font-size: 20px;
    }
}