.agree {

  border-radius: 8px;
  background-color: var(--white);
  box-shadow: var(--dark-gray) 0px 3px 8px;
  position: absolute;
  top: 135px;
  font-size: 14px;
  padding: 20px;
  width: 50%;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  display: none;
  z-index: 100000;
}

.agree.opacity {
  opacity: 1;
  display: block;
}

.agree.no-opactiy {
  opacity: 0;
  display: none;
}

@media (max-width:992px) {
  .agree {
    width: 75%;
  }
}

@media (max-width:768px) {
  .agree {
    width: unset;
    top: 150px;
    font-size: 12px;
    right: 10px;
  }
}