.parser-container {

    position: relative;
    word-wrap: break-word;
    white-space: pre-line;
    display: flex;
    flex-direction: column;
    width: 100%;
}


.border {
    border: none !important;
}



.parser-container.border p {
    margin-bottom: 4px !important;
}

.parser-container.border strong {
    font-weight: 400 !important;
    position: relative !important;
    font-style: italic;
}

.parser-container.border p em {
    position: relative !important;
    padding: 10px 0px !important;

}

.parser-container.border p em::after {
    position: absolute !important;
    left: 0%;
    top: 0;
    display: inline-block;
    content: "";
    width: 100%;
    height: 100%;
    background-repeat: no-repeat !important;
    background-size: contain;
    background-position: 90%;
    background-image: url("../../../assets/images/Border.png");
}

.parser-container p {
    margin: 0;
}

.parser-container.border strong::after {
    position: absolute;
    content: "";
    height: 30px;
    display: inline-block;
    width: 103%;
    background: url("../../../assets/images/borderBottom.png");
    background-repeat: no-repeat;
    top: 90%;
    background-size: 100%;
    right: 0%;
    left: 0%;
}

@media (max-width:768px) {
    .parser-container.border p {
        line-height: 1.5;
    }
}