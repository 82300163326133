@import "bootstrap/dist/css/bootstrap.min.css";
@import "react-toastify/dist/ReactToastify.css";
@import "aos/dist/aos.css";
@import url("./components/common/Aos/aos.css");

@import "react-alice-carousel/lib/alice-carousel.css";
@import "react-responsive-carousel/lib/styles/carousel.min.css";
@import "react-tabs/style/react-tabs.css";
@import url("./assets/css-helpers/variables.css");
@import url("./components/common/form/form.css");
@import url("./components/header/header.css");
@import url("./pages/home/home.css");
@import url("./components/common/domParser/dom-parser.css");
@import url("./components/Tabs/tabs.css");
@import url("./components/common/button/common-button.css");
@import url("./components/common/navs/nav-items.css");
@import url("./components/common/Aos/aos.css");
@import url("./components/footer/footer.css");
@import url("./components/common/swiper/common-swiper.css");
@import url("./components/team/our-team.css");
@import url("./components/youth-section/youth-section.css");
@import url("./components/common/light-blue-container/light-blue-container.css");
@import url("./components/common/firstPageSection/first-page-section.css");
@import url("./components/youth-agency/youth-agency.css");
@import url("./components/common/loading-icon/loading-icon.css");
@import url("./pages/campaings/campaings.css");
@import url("./components/agree/agree.css");
@import url("./pages/projects/project.css");
@import url("./components/common/modal/modal.css");
@import url("./pages/program/program.css");
@import "swiper/css";
@import "swiper/css/bundle";

body {
  margin: 0;

  position: relative;
  background-color: var(--light-gray) !important;

}



iframe {
  max-width: 100%;
}

table {
  width: 100% !important;
}

tr,
td {
  height: initial !important;
}

.border-background {
  font-style: italic;
  background-image: url("./assets/images/Border.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 0px 10px;
}

.not-found_image {
  width: 100vw;
  height: 250px;
}