header {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  height: 16vh;
  width: 100% !important;
  min-height: 115px;
}

.fixed-header {
  position: sticky !important;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  background: linear-gradient(var(--light-gray), rgba(235, 235, 235, 0.95));
}

header a,
header a:hover {
  text-transform: capitalize;
  color: var(--font-color);

}

header .nav-link {
  padding: 0.5rem 10px !important;
}

.triangle {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  /* Adjust size as needed */
  border-right: 10px solid transparent;
  /* Adjust size as needed */
  border-bottom: 10px solid #fff;
  /* Adjust color as needed */
  position: absolute;
  /* Position it relative to the parent */
  top: -10px;
  /* Adjust position relative to dropdown */
  left: 50%;
  /* Center it horizontally */
  transform: translateX(-50%);
  /* Center it using transform */
  z-index: 10;
  /* Ensure it's on top */
}

/* For WebKit browsers (Chrome, Safari, etc.) */
header .nav .dropdown-menu.show::-webkit-scrollbar {
  width: 8px;
  /* Adjust the width here */
}

header .nav .dropdown-menu.show::-webkit-scrollbar-thumb {
  background-color: black;
  /* Black scrollbar */
  border-radius: 10px;
}

header .nav .dropdown-menu.show::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
  /* Light background for the track */
}

/* For Firefox */
header .nav .dropdown-menu.show {
  scrollbar-width: thin;
  /* Thinner scrollbar */
  scrollbar-color: black rgba(0, 0, 0, 0.1);
  /* Black thumb and light track */
}

/* For Internet Explorer and Edge */
header .nav .dropdown-menu.show {
  -ms-overflow-style: scrollbar;
  /* Ensure scrollbars in older versions */
}

/* General styling to ensure smooth cross-browser support */
header .nav .dropdown-menu.show {
  max-width: 100% !important;
  transform: initial !important;
  top: 100% !important;
  left: -64% !important;
  padding: 3px;
  border: 0 !important;
  z-index: 7 !important;
  border-radius: 1px !important;
  opacity: 0.8;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}

header .ar .dropdown-menu.show {
  left: -134% !important;
}



header .ar .dropdown-item {
  text-align: end !important;

}

header.Helvetica-ar .nav .dropdown-menu.show {
  left: -125% !important;
  text-align: end;
}

.dropdown-menu.show::before {
  content: "";
  position: absolute;
  top: -6px;
  right: -1px;
  display: block;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 15px solid var(--white);
  z-index: 10;
}

header .nav .dropdown-item {
  background-color: inherit !important;
  border-bottom: 1px solid var(--light-gray) !important;
  position: relative;
  width: 100%;
  word-wrap: break-word;
  white-space: pre-line;
  z-index: 7 !important;
  font-size: 12px !important;
}

header .nav .dropdown-item::selection {
  background-color: inherit !important;

}

header .dropdown-item:hover {
  background-color: inherit !important;
  color: var(--secondary-font-color) !important;
}

.header_right-side_langauges span {
  padding: 0px 7px;
  cursor: pointer;
}

.header_right-side_langauges span.active {
  color: var(--secondary-font-color);
}

.langauges__ar {
  border-left: 0.5px solid var(--font-color);
}

header .logo {
  height: 50%;
  cursor: pointer;
}

.burger-menu_icon {
  font-size: 28px;
  color: var(--secondary-font-color);
  cursor: pointer;
}

.burger-menu_icon path {
  transition: all 0.3s;
}

.burger-menu_icon.active path:nth-child(2) {
  opacity: 0;
}

.burger-menu_icon.active path:first-child {
  width: 100%;

  transform: rotate(47deg) scale(1.4) translate(-14%, -23%);
}

.burger-menu_icon.active path:last-child {
  transform: rotate(-49deg) scale(1.4) translate(-42%, -45%);
}


.burger-menu {
  display: none;
}

.burger-menu_list {
  display: flex;
  transition: all 0.3s ease-in-out;
  position: absolute;
  background-color: var(--light-gray);
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.burger-menu_list.inactive {
  right: 0;
  left: 0;
  top: 100%;
  opacity: 0;
  height: 0;
  z-index: -100;
}

.burger-menu_list.inactive .nav,
.burger-menu_list.inactive .header_right-side_langauges {
  z-index: -100 !important;
  display: none !important;
}

.burger-menu_list.active {
  right: 0;
  left: 0;
  top: 100%;
  opacity: 1;
  z-index: 100;
  height: 84vh;
}

@media (max-width:1250px) {
  .header_middle-section .nav {
    font-size: 13px !important;
  }
}

@media (max-width:1102.22px) {
  .header_middle-section .nav .nav-link {
    padding: 0 5px !important;
  }
}

@media (max-width:1100px) {
  header .logo {
    height: 40%;
  }

  .burger-menu {
    display: block;
  }

  .header_middle-section,
  .header_right-side_langauges {
    display: none;
  }

  .burger-icon_list {
    display: block;
  }


}