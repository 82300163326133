.first-page-section {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.first-page-section_image {
    height: 150px;
    margin: 0 auto;
}

.first-page-section_idare_title {
    font-size: 30px;
    line-height: 40px;
    text-align: center;
    margin: 0 !important;
    margin-bottom: 5px !important;
    padding: 0;
}

.first-page-section_idare_intro {
    font-size: 12px;
    text-align: center;
    margin: 0;
}

.first-page-section button {
    cursor: pointer !important;
}

@media (max-width:992px) {

    .first-page-section_idare_title {
        font-size: 35px;

    }
}

@media (max-width:768px) {
    .first-page-section_idare_title {
        font-size: 18px;
    }
}